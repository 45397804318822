import { DEFAULT_PRESET, PRESET, type Props } from "./api"

export const PreloadByPreset = {
  [PRESET.FACE]: () => import("@/features/presets/face/preload").then(({ preload }) => preload),
  [PRESET.FACE_180]: () =>
    import("@/features/presets/face-180/preload").then(({ preload }) => preload),
  [PRESET.HAIR]: () => import("@/features/presets/hair/preload").then(({ preload }) => preload),
}

/**
 * Preloads preset‘s resources in advance. For more information, see [Preload Resources](/getting-started/advanced-usage#preload-liqa-resources-before-liqa-session) page.
 * @param options - Preload options.
 * @example
 * ```ts
 * preload({ preset: "face" })
 * ```
 * @category Misc
 */
export const preload = async ({ preset = DEFAULT_PRESET }: PreloadOptions = {}) => {
  const loader = PreloadByPreset[preset]

  if (!loader) {
    console.warn(`Unknown preset "${preset}". Skipping assets preloading.`)
    return
  }

  await loader().then((preload) => preload())
}

/**
 * @category Misc
 */
export type PreloadOptions = Pick<Props, "preset">
